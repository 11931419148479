import React from "react"

import Layout from "../components/layout"
import CutContainer from "../components/cutContainer"
import CustomerList from "../components/customerList"
import EmailInput from "../components/emailInput"
import { grayGradient8 } from "../services/constants"
import styles from "./customersStaff.module.scss"
import Header from "../components/header"
import Footer from "../components/footer"
import globalStyles from "../services/globalStyles.module.scss"
import { graphql } from "gatsby";

class CustomersStaffPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTypeFilters: [],
      activeSportFilters: [],
      activeLocationFilters: []
    }
    this.filterSelected = this.filterSelected.bind(this);
  }

  filterSelected(val, type) {
    var newFilters = type === "type" ? this.state.activeTypeFilters : type === "sport" ? this.state.activeSportFilters : this.state.activeLocationFilters;
    var index = newFilters.indexOf(val);    // <-- Not supported in <IE9
    if (index !== -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push(val);
    }
    type === "type" ? this.setState({activeTypeFilters: newFilters}) : type === "sport" ? this.setState({activeSportFilters: newFilters}) : this.setState({activeLocationFilters: newFilters});
  }
  render() {
    const { data: { customerContent, texts: { _ } }, pageContext } = this.props
    const languageCode = pageContext && pageContext.lang ? pageContext.lang : "en";

    const { activeTypeFilters, activeSportFilters, activeLocationFilters } = this.state;
    const locationValuesToFilterBy = customerContent.edges.reduce((acc, curr) => {
      if (!acc.includes(curr.node.location)) {
        acc.push(curr.node.location);
      }
      return acc;
    }, []);
    const typeValuesToFilterBy = customerContent.edges.reduce((acc, curr) => {
      if (!acc.includes(curr.node.type)) {
        acc.push(curr.node.type);
      }
      return acc;
    }, []);
    const sportValuesToFilterBy = customerContent.edges.reduce((acc, curr) => {
      if (!acc.includes(curr.node.sport)) {
        acc.push(curr.node.sport);
      }
      return acc;
    }, []);

    const filtered = activeTypeFilters.length === 0 && activeSportFilters.length === 0 && activeLocationFilters.length === 0 ? customerContent.edges : customerContent.edges.reduce((acc, curr) => {
      var partOffilter = true;
      if(activeTypeFilters.length !== 0 && !activeTypeFilters.includes(curr.node.type)){
        partOffilter = false;
      }
      if(activeSportFilters.length !== 0 && !activeSportFilters.includes(curr.node.sport)){
        partOffilter = false;
      }
      if(activeLocationFilters.length !== 0 && !activeLocationFilters.includes(curr.node.location)){
        partOffilter = false;
      }

      return partOffilter ? acc.concat(curr) : acc;
    }, [])


    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={500} mobileHeaderFullYOffset={40} fixed languageCode={languageCode}/>} translations={_} languageCode={languageCode}>
        <CutContainer
          style={{
            background: grayGradient8,
            position: "absolute",
          }}
          sizeOfCut={1}
          styleClass={styles.cutContainer}
        >
        </CutContainer>

        <h1 className={styles.header}> Location Filters </h1>
        <div className={styles.filters}>
          {locationValuesToFilterBy.map((val, index) => {
            return <div key={index} className={[styles.filterContainer, activeLocationFilters.includes(val) && styles.activeFilter].join(' ')} onClick={() => this.filterSelected(val, "location")}>{val}</div>
          })}
        </div>
        <h1 className={styles.header}> Type Filters </h1>
        <div className={styles.filters}>
          {typeValuesToFilterBy.map((val, index) => {
            return <div key={index} className={[styles.filterContainer, activeTypeFilters.includes(val) && styles.activeFilter].join(' ')} onClick={() => this.filterSelected(val, "type")}>{val}</div>
          })}
        </div>
        <h1 className={styles.header}> Sport Filters </h1>
        <div className={styles.filters}>
          {sportValuesToFilterBy.map((val, index) => {
            return <div key={index} className={[styles.filterContainer, activeSportFilters.includes(val) && styles.activeFilter].join(' ')} onClick={() => this.filterSelected(val, "sport")}>{val}</div>
          })}
        </div>
        <div className={styles.customerContainer}>
          <CustomerList customers={{edges: filtered}}/>
        </div>
        <div className={styles.emailInputContainer}>
          <EmailInput
            title={_.time_to_get_started}
            placeholder={_.enter_your_email}
            buttonText={_.start_free_trial}
            translations={_.contact_message}
          />
        </div>
        <Header num={2} translations={_} className={globalStyles.hideOnMobile} headerFullYOffset={500} languageCode={languageCode}/>
        <Footer translations={_} languageCode={languageCode}/>
      </Layout>
    )
  }
}

export default CustomersStaffPage

export const query = graphql`
  query CustomersStaff($langFile: String) {
    site{
      siteMetadata{
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        all
        time_to_get_started
        enter_your_email
        start_free_trial
        customers {
          trusted_by
          all_sports
          team_sports
          ind_sports
          pts
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    },
    customerContent: allWordpressCustomers {
      edges {
        node {
          title {
            rendered
          }
          location: wpcf_country
          order: menu_order
          type: wpcf_type
          sport: wpcf_sport
          image: wpcf_logo
        }
      }
    },
  }
`
